<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="form-group mb-0">
              <vSelect
                v-model="selectedDevice"
                @input="onDeviceSelect"
                class="form-control form-control-solid form-control--device-select"
                placeholder="Select device"
                :options="devices"
                :reduce="(device) => device['@id']"
                label="name"
              >
                <template #option="{ name, location, tags }">
                  <span class="mr-3">{{ name }}</span>
                  <span class="badge badge-primary mr-3">{{
                    location.title
                  }}</span>
                  <span v-if="tags[0]" class="badge badge-secondary mr-3">{{
                    tags[0].name
                  }}</span>
                  <span v-if="location.lessor" class="badge badge-secondary">{{
                    location.lessor.name
                  }}</span>
                </template>
              </vSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isHistoryLoaded" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-6">Date</th>
                        <th>Status</th>
                        <th class="6">User</th>
                        <th class="text-right pr-6"> {{ $t('GENERAL.ACTION') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(history, key) in deviceHistories" :key="key">
                        <td :class="{ 'border-top-0': key === 0 }" class="pl-6">
                          <span>{{ history.date | formatDateTime }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="history.meta.serviceModeStatus === true"
                            class="label label-lg label-light-danger label-inline"
                            >On</span
                          >
                          <span
                            v-else
                            class="label label-lg label-light-success label-inline"
                            >Off</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ history.user }}</span>
                        </td>
                        <td
                          class="text-right pr-6"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <button
                            @click.prevent="
                              toggleDeviceServiceModeHistoryModal(history)
                            "
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Clothes/Sun-glasses.svg"
                              />
                            </span>
                          </button>
                          <button
                            @click.prevent="deleteHistory(history.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-modal
          id="device_service_mode_history_modal"
          size="md"
          centered
          hide-header
          hide-footer
        >
          <div v-if="isSelectedMetaLoaded">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('device_service_mode_history_modal')"
              >Close</b-button
            >
            <div>
              {{ selectedMeta }}
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters } from 'vuex'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'ServiceModeHistory',
  components: {
    vSelect,
  },
  data() {
    return {
      devices: [],
      deviceHistories: [],
      selectedDevice: null,
      isHistoryLoaded: false,
      selectedMeta: null,
      isSelectedMetaLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Device Service Mode History', route: '' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get('devices', '?pagination=false').then(({ data }) => {
        this.devices = [...data['hydra:member']]
      })
    }

    if (this.currentUserRole === 'ROLE_ADMIN') {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&pagination=false`
      ).then(({ data }) => {
        this.devices = [...data['hydra:member']]
      })
    }
  },
  methods: {
    async getDevices(resource) {
      try {
        return await ApiService.get(resource)
      } catch (e) {
        throw new Error(e)
      }
    },
    onDeviceSelect() {
      ApiService.get(
        'device_service_mode_histories',
        `?device=${this.selectedDevice}`
      ).then(({ data }) => {
        this.deviceHistories = [...data['hydra:member']]
        this.isHistoryLoaded = true
      })
    },
    toggleDeviceServiceModeHistoryModal(payload) {
      this.isSelectedMetaLoaded = false
      this.selectedMeta = null
      this.selectedMeta = payload.meta
      this.isSelectedMetaLoaded = true

      this.$root.$emit('bv::show::modal', 'device_service_mode_history_modal')
    },
    deleteHistory(id) {
      let confirmation = confirm('....')
      if (confirmation === false) return

      this.isHistoryLoaded = false
      ApiService.delete(`device_service_mode_histories/${id}`).then(() => {
        this.isHistoryLoaded = true
      })
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--device-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
